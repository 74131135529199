import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../layout/Navbar";
import Preloader from "../layout/preloader";
import Footer from "../section-pages/footer";
import ScrollToTopBtn from "../layout/ScrollToTop";
import { createGlobalStyle } from "styled-components";
import { Link, useLocation, useParams } from "react-router-dom";
import { Li } from "react-flags-select";
import { DomainContext } from "..";

const image1 = "../../img/background/subheader-contact.webp";

const GlobalStyles = createGlobalStyle`
  .react-parallax-bgimage {
    transform: translate3d(-50%, -12%, 0px) !important;
  }
`;

export default function Home() {
  const { id } = useParams();
  const { base_url, admin } = useContext(DomainContext);
  const [product, setProduct] = useState([]);
  const [TotalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      if (admin) {
        try {
          const api_url = base_url + "/api/" + "products/show/" + id;
          const response = await fetch(api_url);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const jsonData = await response.json();
          // Ensure jsonData is an array
          if (Array.isArray(jsonData.products)) {
            setProduct(jsonData.products);
            let totalPrice = 0;
            jsonData.products[0].showdetails?.forEach((product_price) => {
              totalPrice += parseFloat(product_price.price);
            });
            setTotalPrice(totalPrice);
            
          } else {
            console.error("Invalid data format: products is not an array");
          }
        } catch (error) {
          console.error("Error fetching data:", error, base_url);
        }
      }
    };
    fetchData();
  }, [base_url, admin]);
  useEffect(() => {
    if (product) {
      console.log("products", product);
    } else {
      console.log("products", "Product object is not available yet.");
    }
  }, [product]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);
  return (
    <>
      {/* HEAD */}
      <Helmet>
        <link rel="icon" href="./img/favicon.png" />
        <title>Premiums Tech Zone - Product</title>
      </Helmet>

      <GlobalStyles />

      {/* LOADER */}
      <div id="mainpreloader">
        <Preloader />
      </div>

      {/* MENU */}
      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* section */}
        <section>
          <div className="container pt-lg-5">
            <div className="row">
              <div className="col-lg-6 ">
                <img
                  src={
                    base_url +
                      `/product_upload/` +
                      (product[0] || {})?.images || ""
                  }
                  className="img-fluid "
                  alt="turnitin"
                />
                <div class="text-gray-500 mt-3">
                  {product[0] && product[0].description ? (
                    <div
                      class="text-gray-500 mt-3 ng-binding"
                      ng-show="product[0].description.length"
                      ng-bind-html="product[0].description | trust"
                    >
                      {product[0].description}
                    </div>
                  ) : (
                    <div
                      class="text-gray-500 mt-3"
                      ng-show="!product[0].description.length"
                    >
                      No description available.
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 ">
                <h1 class="">{product[0] ? product[0].name : ""}</h1>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Warranty
                  </label>
                  <div class="mt-1 position-relative bg-jacarta-700 rounded-md ">
                    {product[0] &&
                      product[0].showdetails?.map((price) => (
                        <label
                          className="position-relative border-secondary rounded-tr-md rounded-tl-md p-4 d-flex justify-content-between align-items-center cursor-pointer border-gray-200 bg-jacarta-700"
                          key={price.validity}
                        >
                          <div className="d-flex align-items-center text-sm">
                            <input
                              type="radio"
                              id="item-0"
                              aria-describedby="item-0-text"
                              className="h-4 w-4 text-indigo-600 border-gray-300 text-jacarta-200"
                              name="209"
                            />
                            <span className="mx-2 font-medium text-secondary">
                              {price.validity} {price.duration}
                            </span>
                          </div>
                          <p className="ml-6 pl-1 text-sm mb-0 ">
                            <span className="font-medium text-secondary">
                              ${price.price}
                            </span>
                          </p>
                        </label>
                      ))}
                  </div>
                </div>
                <div class="mt-4 d-flex gap-3">
                  <Link
                    to="/checkout"
                    className="btn btn-primary width100 fw-bold px-5 py-3 border-0"
                  >
                    Pay ${TotalPrice}
                  </Link>
                  <button className="btn btn-secondary width100 px-5 py-3 border-0">
                    Add To Cart
                  </button>
                </div>
                {/* <div className="row g-4 mt-3 justify-content-center">
                  <div className="col-sm-2 col-4">
                    <div className="p-2 rounded-10 bg-half-white">
                      <img
                        width="70"
                        height="50"
                        src="./img/payments/visa.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-4">
                    <div className="p-2 rounded-10 bg-half-white">
                      <img
                        width="70"
                        height="50"
                        src="./img/payments/mastercard.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-4">
                    <div className="p-2 rounded-10 bg-half-white">
                      <img
                        width="70"
                        height="50"
                        src="./img/payments/paypal.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="col-sm-2 col-4">
                    <div className="p-2 rounded-10 bg-half-white">
                      <img
                        width="70"
                        height="50"
                        src="./img/payments/jcb.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-4">
                    <div className="p-2 rounded-10 bg-half-white">
                      <img
                        width="70"
                        height="50"
                        src="./img/payments/american-express.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        {/* footer */}
        <Footer />
      </div>
      <ScrollToTopBtn />
    </>
  );
}
