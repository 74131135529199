import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";

const NavLink = (props) => {
  return <Link {...props} />;
};

const Navbar = () => {
  const useDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
      setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const closeDropdown = () => {
      setIsOpen(false);
    };

    const ref = useRef(null);
    useOnclickOutside(() => {
      closeDropdown();
    }, ref);

    return {
      isOpen,
      toggleDropdown,
      closeDropdown,
      ref,
    };
  };

  const { isOpen: openMenu, toggleDropdown: handleBtnClick, closeDropdown: closeMenu, ref } = useDropdown();
  const { isOpen: openMenu1, toggleDropdown: handleBtnClick1, closeDropdown: closeMenu1, ref: ref1 } = useDropdown();
  const { isOpen: openMenu2, toggleDropdown: handleBtnClick2, closeDropdown: closeMenu2, ref: ref2 } = useDropdown();
  const { isOpen: openMenu3, toggleDropdown: handleBtnClick3, closeDropdown: closeMenu3, ref: ref3 } = useDropdown();
  const { isOpen: openMenu4, toggleDropdown: handleBtnClick4, closeDropdown: closeMenu4, ref: ref4 } = useDropdown();

  const [showmenu, setBtnIcon] = useState(false);

  useEffect(() => {
    const header = document.getElementById("header-wrap");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;

    const scrollCallBack = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
    };

    window.addEventListener("scroll", scrollCallBack);

    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <nav className="navbar transition">
      <div className="container">

        {/********* Logo *********/}
        <NavLink className="navbar-brand" to="/">
          <img src="./img/logo.png" className="img-fluid d-md-block d-none imginit logo-premium" alt="logo" />
          <img src="./img/mobile-logo.png" className="img-fluid d-md-none d-sms-none imginit logo-mobile" alt="logo" />
        </NavLink>
        {/********* Logo *********/}

        {/********* Mobile Menu *********/}
        <div className="mobile">
          {showmenu &&
            <div className='menu'>

              <div className='navbar-item counter d-none'>
                <div ref={ref}>
                  <div className="dropdown-custom dropdown-toggle btn"
                    onClick={() => {
                      handleBtnClick();
                      closeMenu1();
                      closeMenu2();
                      closeMenu3();
                      closeMenu4();
                    }}>
                    Home
                  </div>
                  {openMenu && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu}>
                        <NavLink to="/" onClick={() => setBtnIcon(!showmenu)}>Homepage One</NavLink>
                        <NavLink to="/home1" onClick={() => setBtnIcon(!showmenu)}>Homepage Two</NavLink>
                        <NavLink to="/home2" onClick={() => setBtnIcon(!showmenu)}>Homepage Three</NavLink>
                        <NavLink to="/home3" onClick={() => setBtnIcon(!showmenu)}>Homepage Four</NavLink>
                        <NavLink to="/home4" onClick={() => setBtnIcon(!showmenu)}>Homepage Five</NavLink>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='navbar-item counter d-none'>
                <div ref={ref1}>
                  <div className="dropdown-custom dropdown-toggle btn"
                    onClick={() => {
                      handleBtnClick1();
                      closeMenu();
                      closeMenu2();
                      closeMenu3();
                      closeMenu4();
                    }}>
                    Game Servers
                  </div>
                  {openMenu1 && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu1}>
                        <NavLink to="/games" onClick={() => setBtnIcon(!showmenu)}>Games Server List</NavLink>
                        <NavLink to="/bundle" onClick={() => setBtnIcon(!showmenu)}>Pricing Table One</NavLink>
                        <NavLink to="/pricing2" onClick={() => setBtnIcon(!showmenu)}>Pricing Table Two</NavLink>
                        <NavLink to="/pricing3" onClick={() => setBtnIcon(!showmenu)}>Pricing Table Three</NavLink>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='navbar-item counter'>
                <NavLink to="/" onClick={() => setBtnIcon(!showmenu)}>
                  Home
                </NavLink>
              </div>
              <div className='navbar-item counter'>
                <NavLink to="/subscription" onClick={() => setBtnIcon(!showmenu)}>
                  Subscription
                </NavLink>
              </div>
              <div className='navbar-item counter'>
                <NavLink to="/location" onClick={() => setBtnIcon(!showmenu)}>
                  Home
                </NavLink>
              </div>
              <div className='navbar-item counter'>
                {/* /pricing */}
                <NavLink to="/bundle" onClick={() => setBtnIcon(!showmenu)}>
                  Bundle
                </NavLink>
              </div>
              <div className='navbar-item counter'>
                <NavLink to="/about" onClick={() => setBtnIcon(!showmenu)}>
                  About Us
                </NavLink>
              </div>
              <div className='navbar-item counter'>
                <NavLink to="/contact" onClick={() => setBtnIcon(!showmenu)}>

                  Contact Us
                </NavLink>
              </div>

              <div className='navbar-item counter d-none'>
                <div ref={ref2}>
                  <div className="dropdown-custom dropdown-toggle btn"
                    onClick={() => {
                      handleBtnClick2();
                      closeMenu1();
                      closeMenu();
                      closeMenu3();
                      closeMenu4();
                    }}>
                    Support
                  </div>
                  {openMenu2 && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu2}>
                        <NavLink to="/knowledgebase" onClick={() => setBtnIcon(!showmenu)}>Knowledgebase</NavLink>
                        <NavLink to="/faq" onClick={() => setBtnIcon(!showmenu)}>FAQ</NavLink>
                        <NavLink to="/contact" onClick={() => setBtnIcon(!showmenu)}>Contact</NavLink>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='navbar-item counter d-none'>
                <NavLink to="/news" onClick={() => setBtnIcon(!showmenu)}>
                  News
                </NavLink>
              </div>

              <div className='navbar-item counter d-none'>
                <div ref={ref3}>
                  <div className="dropdown-custom dropdown-toggle btn"
                    onClick={() => {
                      handleBtnClick3();
                      closeMenu1();
                      closeMenu2();
                      closeMenu();
                      closeMenu4();
                    }}>
                    Company
                  </div>
                  {openMenu3 && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu3}>
                        <NavLink to="/about" onClick={() => setBtnIcon(!showmenu)}>About Us</NavLink>
                        <NavLink to="/affliate" onClick={() => setBtnIcon(!showmenu)}>Affliates</NavLink>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='navbar-item counter d-none'>
                <div ref={ref4}>
                  <div className="dropdown-custom dropdown-toggle btn"
                    onClick={() => {
                      handleBtnClick4();
                      closeMenu1();
                      closeMenu2();
                      closeMenu3();
                      closeMenu();
                    }}>
                    More Pages
                  </div>
                  {openMenu4 && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu4}>
                        <NavLink to="/login" onClick={() => setBtnIcon(!showmenu)}>Login</NavLink>
                        <NavLink to="/register" onClick={() => setBtnIcon(!showmenu)}>Register</NavLink>
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </div>
          }
        </div>
        {/********* Mobile Menu *********/}

        {/********* Dekstop Menu *********/}
        <div className="dekstop">
          <div className='menu'>
            <div className='navbar-item counter'>
              <NavLink to="/">
                Home
              </NavLink>
            </div>
            <div className='navbar-item counter'>
              {/* /pricing3 */}
              <NavLink to="/subscription">
                Subscription
              </NavLink>
            </div>

            <div className='navbar-item counter'>
              <NavLink to="/bundle">
                Bundle
              </NavLink>
            </div>

            <div className='navbar-item counter'>
              <NavLink to="/about">
                About Us
              </NavLink>
            </div>

            <div className='navbar-item counter'>
              <NavLink to="/contact">
                Contact Us
              </NavLink>
            </div>


            <div className='navbar-item counter d-none'>
              <div ref={ref}>
                <div className="dropdown-custom dropdown-toggle btn"
                  onMouseEnter={handleBtnClick} onMouseLeave={closeMenu}>
                  Home
                  {openMenu && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu}>
                        <NavLink to="/">Homepage One</NavLink>
                        <NavLink to="/home1">Homepage Two</NavLink>
                        <NavLink to="/home2">Homepage Three</NavLink>
                        <NavLink to="/home3">Homepage Four</NavLink>
                        <NavLink to="/home4">Homepage Five</NavLink>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            </div>

            <div className='navbar-item counter d-none'>
              <div ref={ref1}>
                <div className="dropdown-custom dropdown-toggle btn"
                  onMouseEnter={handleBtnClick1} onMouseLeave={closeMenu1}>
                  Game Servers
                  {openMenu1 && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu1}>
                        <NavLink to="/games">Games Server List</NavLink>
                        <NavLink to="/bundle">Pricing Table One</NavLink>
                        <NavLink to="/pricing2">Pricing Table Two</NavLink>
                        <NavLink to="/pricing3">Pricing Table Three</NavLink>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            </div>

            <div className='navbar-item counter d-none'>
              <NavLink to="/location">
                Locations
              </NavLink>
            </div>

            <div className='navbar-item counter d-none'>
              <div ref={ref2}>
                <div className="dropdown-custom dropdown-toggle btn"
                  onMouseEnter={handleBtnClick2} onMouseLeave={closeMenu2}>
                  Support
                  {openMenu2 && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu2}>
                        <NavLink to="/knowledgebase">Knowledgebase</NavLink>
                        <NavLink to="/faq">FAQ</NavLink>
                        <NavLink to="/contact">Contact</NavLink>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            </div>

            <div className='navbar-item counter d-none'>
              <NavLink to="/news">
                News
              </NavLink>
            </div>

            <div className='navbar-item counter d-none'>
              <div ref={ref3}>
                <div className="dropdown-custom dropdown-toggle btn"
                  onMouseEnter={handleBtnClick3} onMouseLeave={closeMenu3}>
                  Company
                  {openMenu3 && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu3}>
                        <NavLink to="/about">About Us</NavLink>
                        <NavLink to="/affliate">Affliates</NavLink>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            </div>

            <div className='navbar-item counter d-none'>
              <div ref={ref4}>
                <div className="dropdown-custom dropdown-toggle btn"
                  onMouseEnter={handleBtnClick4} onMouseLeave={closeMenu4}>
                  More Pages
                  {openMenu4 && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu4}>
                        <NavLink to="/login">Login</NavLink>
                        <NavLink to="/register">Register</NavLink>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            </div>

          </div>
        </div>
        {/********* Dekstop Menu *********/}

        {/********* Side Button *********/}
        <div className="menu_side_area">
          <NavLink to="#" className="btn-line d-none" id="btn-line">Get Hosting</NavLink>
          <div className='navbar-item counter pm-0'>
            <div ref={ref4}>
              <div className="dropdown-custom dropdown-toggle btn"
                onMouseEnter={handleBtnClick4} onMouseLeave={closeMenu4}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path fill="white" d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                </svg>
                {openMenu4 && (
                  <div className='item-dropdown'>
                    <div className="dropdown" onClick={closeMenu4}>
                      <NavLink to="/login">Login</NavLink>
                      <NavLink to="/register">Register</NavLink>
                      <NavLink to="/history">Order History</NavLink>
                      <NavLink to="/profile">Edit Profile</NavLink>
                      <NavLink to="/logout">Sign Out</NavLink>
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>

          <div className='navbar-item counter pm-0'>
            <NavLink to="/checkout">
              <div className="dropdown-custom btn" >
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" className="h-4 w-4 me-2" viewBox="0 0 576 512" >
                  <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                </svg>
                <span>$ 0.00</span>
              </div>
            </NavLink>
          </div>




          {/********* Burger Button *********/}
          <button className="burgermenu" type="button"
            onClick={() => {
              setBtnIcon(!showmenu);
              closeMenu1();
              closeMenu2();
              closeMenu3();
              closeMenu4();
            }}>
            <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
          {/********* Burger Button *********/}
        </div>

        {/********* Side Button *********/}



      </div>

    </nav >
  )
}

export default Navbar;