import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/elegant-icons/style.css";
import "../node_modules/et-line/style.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/bootstrap.min.css";
import "./assets/aos.css";
import "./assets/animated.css";
import "./assets/swiper-bundle.min.css";
import "./assets/coloring.css";
import "./assets/globals.css";
import "./assets/Home.module.css";
import "./assets/maincolor.css";
import "./assets/style.scss";
import App from "./main";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
export const DomainContext = React.createContext();

const FetchAdmin = () => {
  const [admin, setAdmin] = useState(null);
  const domain = window.location.hostname;
  console.log(domain);
  const base_url = "https://funzone.einnovention.co.uk";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(base_url + "/api/" + "domain/" + domain);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setAdmin(jsonData.admin);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [domain,base_url]);

  useEffect(() => {
    console.log(admin); // Log admin whenever it changes
  }, [admin]);

  return (
    <React.StrictMode>
      <DomainContext.Provider value={{ base_url, admin }}>
        <App />
      </DomainContext.Provider>
    </React.StrictMode>
  );
};

root.render(<FetchAdmin />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
