import React from 'react';


const Section = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="subtitle mb-3">Incredibly features</div>
                    <h2 className="mb20">Premium Subscriptions</h2>
                </div>

                <div className="col-lg-6"></div>

                <div className="col-lg-3 col-md-6 mb-sm-20" >
                    <div>
                        <img src="./img/icons/1.png" className="mb20" alt=""/>
                        <h4>Super Quick Setup</h4>
                        <p>Get started in minutes with our super quick setup process.</p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-sm-20">
                    <div>
                        <img src="./img/icons/2.png" className="mb20" alt=""/>
                        <h4>Premium Hardware</h4>
                        <p>Experience top-notch performance with our premium hardware selection.</p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-sm-20">
                    <div>
                        <img src="./img/icons/3.png" className="mb20" alt=""/>
                        <h4>Flexible Payments</h4>
                        <p>Choose from flexible payment options that suit your budget and needs.</p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-sm-20">
                    <div>
                        <img src="./img/icons/4.png" className="mb20" alt=""/>
                        <h4>Fast Support</h4>
                        <p>Enjoy fast and responsive support whenever you need assistance.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section;