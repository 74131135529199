import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Install Swiper modules
// SwiperCore.use([Pagination, Autoplay]);

const Customerreviews = () => {
    
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="subtitle mb20">Customer reviews</div>
                        <h2 className="wow fadeInUp">4.85 out of 5</h2>
                        <div className="spacer-20"></div>
                    </div>
                </div>
            </div>
            <Swiper className="smallslider"
             modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={20}
                slidesPerView="4"
                // navigation
                pagination={{ clickable: true }}
                // centeredSlides
                loop={true}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false, // Allow interaction to stop autoplay
                    pauseOnMouseEnter: true, // Pause autoplay on mouse hover
                    waitForTransition: true, // Wait for transition to complete before next slide
                    effect: 'fade', // Use fade effect for smooth transition
                    speed: 800, // Transition speed in milliseconds
                }} 
            >
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
              <blockquote>
                  <div className="de-rating-ext">
                      <span className="d-stars">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                      </span>
                  </div>
                  <p>&quot;I recently discovered Premiums Tech Zone, and it has exceeded my expectations. Their platform is intuitive, and I love how I can easily manage all my software subscriptions in one place.&quot;
                  </p>
                  <div className="de_testi_by">
                      <img alt="" src="./img/people/1.jpg"/> <span>Michael S.</span>
                  </div>
              </blockquote>
          </div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Premiums Tech Zone has exceeded my expectations in terms of both service and affordability. I've been able to access premium software tools for my business without breaking the bank. &quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="./img/people/2.jpg"/> <span>Robert L.</span>
                    </div>
                </blockquote>
            </div>
        </div>         
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Premiums Tech Zone has been instrumental in helping me streamline my software subscriptions. Their platform is intuitive, and I love the savings I get with their affordable plans. &quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="./img/people/3.jpg"/> <span>Jake M.</span>
                    </div>
                </blockquote>
            </div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                  <blockquote>
                      <div className="de-rating-ext">
                          <span className="d-stars">
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                          </span>
                      </div>
                      <p>I've been a loyal customer of Premiums Tech Zone for a while now, and I couldn't be happier with their service. They offer a wide range of software options at competitive prices.&quot;</p>
                      <div className="de_testi_by">
                          <img alt="" src="./img/people/4.jpg"/> <span>Alex P.</span>
                      </div>
                  </blockquote>
              </div>
        </div>         
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Premiums Tech Zone is my go-to platform for all my software needs. Their subscription options are affordable, and the service is top-notch. Highly recommended!&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="./img/people/5.jpg"/> <span>Carlos R.</span>
                    </div>
                </blockquote>
            </div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Premiums Tech Zone has made it effortless for me to access the latest software tools I need for my projects. Their subscription plans are flexible, and the platform is user-friendly.&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="./img/people/6.jpg"/> <span>Edward B.</span>
                    </div>
                </blockquote>
            </div>
        </div>         
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;As a designer, Premiums Tech Zone has been a lifesaver for me. I can easily find and subscribe to popular design software like Adobe and Canva without any hassle.&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="./img/people/7.jpg"/> <span>Daniel H.</span>
                    </div>
                </blockquote>
            </div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Premiums Tech Zone has simplified the way I manage my software subscriptions. Their platform is reliable, and I appreciate the convenience of having all my subscriptions in one place.&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="./img/people/8.jpg"/> <span>Bryan G.</span>
                    </div>
                </blockquote>
            </div>
        </div>         
      </SwiperSlide>
    </Swiper>
    </>
  );
};
export default Customerreviews;