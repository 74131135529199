import React from 'react';
import { Link } from "react-router-dom";

const footer = () => {
    return(
        <footer>
            <div className="container">
                <div className="row gx-5">
                    <div className="col-lg-4">
                        <img src="./img/logo.png" className='logo-premium' alt="logo" />
                        <div className="spacer-20"></div>
                        <p>The world's largest online store for digital goods. Shop with confidence and rest assured knowing that all of our digital goods are fully covered by our warranty.</p>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                                <div className="widget">
                                    <h5>Our Products</h5>
                                    <ul>
                                        <li><Link to="/">Canva Pro</Link></li>
                                        <li><Link to="/">Adobe Pro</Link></li>
                                        <li><Link to="/">Youtube Premier</Link></li>
                                        <li><Link to="/">WIX Logo Maker</Link></li>
                                        <li><Link to="/">Elementor Pro</Link></li>
                                        {/* <li><Link to="/">Ecommerece</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="widget">
                                    <h5>Pages</h5>
                                    <ul>
                                        <li><Link to="/">Subscription</Link></li>
                                        <li><Link to="/">Bundle</Link></li>
                                        <li><Link to="/">About Us</Link></li>
                                        <li><Link to="/">Contact Us</Link></li>
                                        <li><Link to="/">Locations</Link></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="widget">
                            <h5>Newsletter</h5>
                            <form action="blank.php" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                            <div className="col text-center">
                                <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="Enter your email" type="text" /> <Link to="/" id="btn-subscribe"><i className="arrow_right bg-color-secondary"></i></Link>
                                <div className="clearfix"></div>
                            </div>
                            </form>
                            <div className="spacer-10"></div>
                            <small>Your email is safe with us. We don&apos;t spam.</small>
                            <div className="spacer-30"></div>
                            <div className="widget">
                                <h5>Follow Us on</h5>
                                <div className="social-icons">
                                    <Link to="/"><i className="fa-brands fa-facebook-f"></i></Link>
                                    <Link to="/"><i className="fa-brands fa-twitter"></i></Link>
                                    <Link to="/"><i className="fa-brands fa-discord"></i></Link>
                                    <Link to="/"><i className="fa-brands fa-tiktok"></i></Link>
                                    <Link to="/"><i className="fa-brands fa-youtube"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                           Copyright 2024 - Premiums Tech Zone by <Link to="https://einnovention.us/" className='text-secondary'>Einnovention</Link> 
                        </div>
                        <div className="col-lg-6 col-sm-6 text-lg-end text-sm-start">
                            <ul className="menu-simple">
                                <li><Link to="/terms-and-conditions">Terms &amp; Conditions</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default footer;