import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Parallax } from "react-parallax";
import Navbar from "../layout/Navbar";
import Preloader from "../layout/preloader";
import Pricelist from "../section-pages/pricelist-horizontal";
import Section1 from "../section-pages/section-1";
import Footer from "../section-pages/footer";
import ScrollToTopBtn from "../layout/ScrollToTop";
import { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";

const image2 = "./img/background/subheader-game.webp";
const orderbg = "./img/background/order-history.jpg";

const GlobalStyles = createGlobalStyle`

`;

export default function Home() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);
  return (
    <>
      {/* HEAD */}
      <Helmet>
        <link rel="icon" href="./img/favicon.png" />
        <title>Premiums Tech Zone - My Orders</title>
      </Helmet>

      <GlobalStyles />

      {/* LOADER */}
      <div id="mainpreloader">
        <Preloader />
      </div>

      {/* MENU */}
      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* <Parallax className="bgcolor" bgImage={image2} strength={300}>
          <div className="de-gradient-edge-bottom"></div>
          <section className="no-bg">
            <div className="container z-1000">
              <div className="text-center align-items-center">
                <h2 className="mb-0 mt-lg-5">My Orders</h2>
              </div>
            </div>
          </section>
        </Parallax> */}

        <Parallax className="" bgImage={orderbg} strength={300}>
          {/* section */}
          <section className="no-top no-bg mt-5">
            <div className="container" style={{marginTop:'120px'}}>
            <div className="container z-1000 m-5" >
              <div className=" align-items-center">
                <h2 className="mb-0 mt-lg-5" >My Orders</h2>
              </div>
            </div>
              <table className="table table-pricing dark-style text-center">
                <thead>
                  <tr>
                    <th scope="col">ORDER</th>
                    <th scope="col">WARRANTY</th>
                    <th scope="col">STATUS</th>
                    <th scope="col">PRICE</th>
                    <th scope="col">DATE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <th>Turnitin</th>
                    <td>-</td>
                    <td>
                      <div>
                        <span class="text-dark  mr-2 px-2 py-0.5 rounded bg-warning badge">
                          Pending
                        </span>
                      </div>
                    </td>

                    <td className="d-spc">
                      <span className="opt-1">$9.99</span>
                    </td>
                    <td>
                      <p>Mar 17, 2024 11:20 PM</p>
                    </td>
                  </tr>
                  <tr className="">
                    <th>Turnitin</th>
                    <td>-</td>
                    <td>
                      <div>
                        <span class="text-dark  mr-2 px-2 py-0.5 rounded bg-secondary badge">
                          Unpaid
                        </span>
                      </div>
                    </td>

                    <td className="d-spc">
                      <span className="opt-1">$19.99</span>
                    </td>
                    <td>
                      <p>Mar 17, 2024 11:20 PM</p>
                    </td>
                  </tr>
                  <tr className="">
                    <th>Turnitin</th>
                    <td>-</td>
                    <td>
                      <div>
                        <span class="text-dark  mr-2 px-2 py-0.5 rounded bg-success badge">
                          Completed
                        </span>
                      </div>
                    </td>

                    <td className="d-spc">
                      <span className="opt-1">$49.99</span>
                    </td>
                    <td>
                      <p>Mar 17, 2024 11:20 PM</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </Parallax>

        {/* section */}
        <section className="no-top">
          <Section1 />
        </section>

        {/* footer */}
        <Footer />
      </div>
      <ScrollToTopBtn />
    </>
  );
}
