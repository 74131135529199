import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../layout/Navbar";
import Preloader from "../layout/preloader";
import Footer from "../section-pages/footer";
import ScrollToTopBtn from "../layout/ScrollToTop";
import { createGlobalStyle } from "styled-components"; 
const image1 = "../../img/background/subheader-contact.webp";

const GlobalStyles = createGlobalStyle`
  .react-parallax-bgimage {
    transform: translate3d(-50%, -12%, 0px) !important;
  }
`;

export default function Home() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);
  return (
    <>
      {/* HEAD */}
      <Helmet>
        <link rel="icon" href="./img/favicon.png" />
        <title>Premiums Tech Zone - Product</title>
      </Helmet>

      <GlobalStyles />

      {/* LOADER */}
      <div id="mainpreloader">
        <Preloader />
      </div>

      {/* MENU */}
      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* section */}
        <section>
          <div class="container pt-lg-5">
            <h1 class="font-display text-jacarta-700 text-center text-4xl font-medium dark:text-white">
              Return Policy
            </h1>
            <div class="article-content mx-auto max-w-[48.125rem] mt-4">
              <p>
                Our products are mostly virtual and digital in nature. All
                customers with active warranties are entitled to a refund of
                their purchase.
              </p>
              <p>
                Upon receipt of the returned product, we will fully examine it
                and notify you via email, within a reasonable period of time,
                whether you are entitled to a refund or a replacement as a
                result of the defect. If you are entitled to a replacement or
                refund, we will replace the product or refund the purchase price
                using the original method of payment.
              </p>

              <h2>Exchanges</h2>
              <p>
                We only exchange goods if they are defective or damaged. In
                circumstances where you consider that a product is defective,
                you should promptly contact us at support@accountify.black with
                details of the product and the defect. You can send the item you
                consider defective to: Digital products that have already been
                used will not be eligible for a refund. You may terminate your
                subscription only within the duration of your order warranty,
                and in such a case, you will receive a refund of the fees you
                paid within those 48 hours for the subscription granted that the
                subscription was rendered unused. Other than as described above,
                the fees you pay to unlock a paid license of the Product are not
                refundable if you attempt to terminate 48 or more hours from
                when you were charged.
              </p>

              <h2>Shipping</h2>
              <p>
                To return the item you purchased, please mail it to:
                support@accountify.black
              </p>
              <p>
                Refunds do not include any shipping and handling charges
                incurred. Shipping charges for all returns must be prepaid and
                insured by you. You are responsible for any loss or damage to
                hardware during shipment. We do not guarantee that we will
                receive your returned item. Shipping and handling charges are
                not refundable. Any amounts refunded will not include the cost
                of shipping.
              </p>
            </div>
          </div>
        </section>

        {/* footer */}
        <Footer />
      </div>
      <ScrollToTopBtn />
    </>
  );
}
