import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { DomainContext } from "..";

const Help = () => {
  const { base_url, admin } = useContext(DomainContext);
  const [Faq, setFaq] = useState([]);
  const [Length, setLength] = useState(1);
  useEffect(() => {
    const fetchData = async () => {
      if (admin) {
        try {
          const api_url = base_url + "/api/" + "faq-list/" + admin.id;
          const response = await fetch(api_url);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const jsonData = await response.json();
          // Ensure jsonData is an array
          if (Array.isArray(jsonData.faq)) {
            setFaq(jsonData.faq);
          } else {
            console.error("Invalid data format: Faq is not an array");
          }
        } catch (error) {
          console.error("Error fetching data:", error, base_url);
        }
      }
    };
    fetchData();
  }, [base_url, admin]);
  useEffect(() => {
    let loop = Math.round(Faq.length / 5);
    if (loop == 0) {
      loop++;
    }
    setLength(loop);
    console.log(Length);
  }, [Faq]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="subtitle  mb-3">Do you have</div>
          <h2 className=" mb20">Any questions?</h2>
        </div>

        <div className="clearfix"></div>
        {Array.from({ length: Length }, (_, index) => (
        <div className="col-lg-6" key={index}>
          {/* <div className="subtitle mb-3">Do you have</div> */}
          {/* Render FAQ inside the loop */}
          <div className="accordion accordion-flush" id={`accflush-${index}`}>
            {Faq.slice(index * 5, (index + 1) * 5).map((faq, faqIndex) => (
              <div className="accordion-item" key={faqIndex}>
                <h3 className="accordion-header" id={`flush-h${index}-${faqIndex}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-c${index}-${faqIndex}`}
                    aria-expanded="false"
                    aria-controls={`flush-c${index}-${faqIndex}`}
                  >
                    <p className="mb-0 questions">{faq ? faq.question : ""}</p>
                  </button>
                </h3>
                <div
                  id={`flush-c${index}-${faqIndex}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`flush-h${index}-${faqIndex}`}
                  data-bs-parent={`#accflush-${index}`}
                >
                  <div className="accordion-body">
                    <p>{faq ? faq.answer : ""}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default Help;
