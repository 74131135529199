import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../layout/Navbar";
import Preloader from "../layout/preloader";
import Footer from "../section-pages/footer";
import ScrollToTopBtn from "../layout/ScrollToTop";
import { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Li } from "react-flags-select";
const image1 = "../../img/background/subheader-contact.webp";

const GlobalStyles = createGlobalStyle`
  .react-parallax-bgimage {
    transform: translate3d(-50%, -12%, 0px) !important;
  }
`;

export default function Home() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);
  const [itemCount, setItemCount] = useState(5); // Initial item count

  const decreaseItemCount = () => {
    if (itemCount > 0) {
      setItemCount(itemCount - 1);
    }
  };

  const increaseItemCount = () => {
    setItemCount(itemCount + 1);
  };

  const removeItem = () => {
    // Add logic to remove item from the cart
    setItemCount(0);
  };
  return (
    <>
      {/* HEAD */}
      <Helmet>
        <link rel="icon" href="./img/favicon.png" />
        <title>Premiums Tech Zone - Product</title>
      </Helmet>

      <GlobalStyles />

      {/* LOADER */}
      <div id="mainpreloader">
        <Preloader />
      </div>

      {/* MENU */}
      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>
        {/* empty cart section */}
        <section className="bg-jacarta-900">
          <div className="container pt-lg-5">
            <div className="text-center">
              <h1 class="text-center text-jacarta-200">
                Your Cart is Currently Empty!
              </h1>
              <img
                src="./img/icons/empty-cart-dark.svg"
                className="img-fluid mt-lg-4 mt-3 empty-cart-dark"
                alt="empty-cart-dark"
              />
              <p className="text-muted px-lg-5 mt-lg-4 mt-3">
                Before proceeding to checkout you must add some products to your shopping cart. <br />
                You will find a lot of interesting products on our "Shop" page.
              </p>
              <Link to="/" className="btn btn-primary px-5 py-2 mt-lg-4 mt-3 border-0 fw-bold ">Return to Shop</Link>
            </div>
          </div>
        </section>
        {/* section */}
        <section className="bg-jacarta-900">
          <div className="container pt-lg-5">
            <h1 class="">
              Shopping Cart
            </h1>
            <div className="row">
              <div className="col-lg-7 ">
                <section className="bg-jacarta-900">
                  <ul className="border-t  p-0  border-jacarta-600">
                    <li className="shopping-list py-3 border-b">
                      <div className="product-img bg-jacarta-800 mr-3">
                        <img
                          src="./img/covers/turnitin.png"
                          className="img-fluid "
                          alt="turnitin"
                        />
                      </div>
                      <div className="position-relative ml-4 d-flex justify-content-between width100">
                        <div className="pr">
                          <h3 className="text-sm pro-name">
                            <Link to="/product" className="font-medium">
                              Turnitin
                            </Link>
                          </h3>
                          <p class="mt-1 text-sm text-accent ">
                            1 month Warranty
                          </p>
                        </div>
                        <div>
                          <div className="rounded-3 border border-jacarta-600 border-gray-300 px-2 d-flex justify-content-between">
                            <button className="bg-transparent text-secondary text-sm border-0 text-bold" onClick={decreaseItemCount}>
                              -
                            </button>
                            <span className="px-2 text-center text-bold dark:text-jacarta-100 ng-binding">
                              {itemCount}
                            </span>
                            <button className="bg-transparent text-secondary text-sm border-0 text-bold" onClick={increaseItemCount}>
                              +
                            </button>
                          </div>

                          <button type="button" className="bg-transparent text-secondary text-small border-0" onClick={removeItem}>
                            <span>Remove</span>
                          </button>
                        </div>
                        <p class="text-sm font-medium text-gray-900 dark:text-jacarta-100 text-right ng-binding">$422.94</p>
                      </div>
                    </li>
                  </ul>
                </section>
              </div>

              <div className="col-lg-5 ">
                <section className=" bg-jacarta-900 " style={{ marginBottom: '0px !important' }}>
                  <div className=" bg-jacarta-800 rounded-3 p-4 ">
                    <h2 id="summary-heading" class="text-lg font-medium text-gray-900 dark:text-gray-50">Order summary</h2>

                    <dl class="mt-6 space-y-4">
                      <div class="d-flex align-items-center justify-content-between">
                        <dt class="text-sm text-gray-600 dark:text-jacarta-200">Subtotal</dt>
                        <dd class="text-sm font-medium text-gray-900 dark:text-jacarta-50 ng-binding">{setItemCount}</dd>
                      </div>
                      <div class="border-t border-gray-200 dark:border-jacarta-600 pt-4 ng-hide" ng-show="cart.discounts.length > 0">
                        <dt class="d-flex align-items-center justify-content-between text-sm text-gray-600 dark:text-jacarta-200">Discount</dt>

                      </div>
                      <div class="border-t border-gray-200 dark:border-jacarta-600 pt-4 d-flex align-items-center justify-content-between">
                        <dt class="text-base font-medium text-gray-900 dark:text-jacarta-200">Order total</dt>
                        <dd class="text-base font-medium text-gray-900 dark:text-jacarta-50 ng-binding">$422.94</dd>
                      </div>
                    </dl>

                  </div>
                <div className=" bg-jacarta-900 mt-3">
                  <div className=" bg-jacarta-800 rounded-3 p-4 ">
                    <div className=" align-items-center payment-form">
                      <label className="text-sm text-gray-600 dark:text-jacarta-200">Payment Method</label>
                      <select className="text-white b-0 form-select bg-jacarta-800 " aria-label="Default select example" >
                        <option selected disabled>Select Payment Method</option>
                        <option value="">Paypal</option>
                        <option value="">VISA</option>
                        <option value="">American Express</option>
                      </select>
                    </div>

                    <div class=" pt-3">
                      <label htmlFor="">Email for Delivery (CHECK JUNK / SPAM FOLDER)</label>
                      <input type="text" className="form-control bg-transparent text-white" />
                      <p>You're checking out as a guest. <Link href="/login" className="fw-bold text-white">Sign in?</Link></p>
                    </div>
                    <div class="pt-2">
                     <button className="btn btn-primary w-100 py-2">Proceed to Checkout</button>
                    </div>
                  </div>
                </div>
                </section>

              </div>
            </div>
          </div>
        </section>

        {/* footer */}
        <Footer />
      </div>
      <ScrollToTopBtn />
    </>
  );
}
