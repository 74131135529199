import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../layout/Navbar";
import Preloader from "../layout/preloader";
import Footer from "../section-pages/footer";
import ScrollToTopBtn from "../layout/ScrollToTop";
import { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";
import { Li } from "react-flags-select";
const image1 = "../../img/background/subheader-contact.webp";

const GlobalStyles = createGlobalStyle`
  .react-parallax-bgimage {
    transform: translate3d(-50%, -12%, 0px) !important;
  }
`;

export default function Home() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);
  return (
    <>
      {/* HEAD */}
      <Helmet>
        <link rel="icon" href="./img/favicon.png" />
        <title>Premiums Tech Zone - Product</title>
      </Helmet>

      <GlobalStyles />

      {/* LOADER */}
      <div id="mainpreloader">
        <Preloader />
      </div>

      {/* MENU */}
      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>
        {/* empty cart section */}
        <section className="bg-jacarta-900">
          <div className="container pt-lg-5">
            <div className="text-center">
            <img
                  src="./img/icons/404.png"
                  className="img-fluid  not-found"
                  alt="404"
                  />
            <h1 class="text-center text-jacarta-200 mt-lg-4 mt-3">
            Page Not Found!
            </h1>
                  <p className="text-muted px-lg-5 mt-lg-4 mt-3">
                  Oops! The page you are looking for does not exist. <br/> It might have been moved or deleted.
            </p>
            <Link to="/" className="btn btn-primary px-5 py-2 border-0 fw-bold mt-lg-4 mt-3">Navigate Back Home</Link>
                  </div>
            </div>
            </section> 

        {/* footer */}
        <Footer />
      </div>
      <ScrollToTopBtn />
    </>
  );
}
