import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { DomainContext } from "..";

const Collection = () => {
  const { base_url, admin } = useContext(DomainContext);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      if (admin) {
        try {
          const api_url = base_url + "/api/" + "products-list/" + admin.id;
          const response = await fetch(api_url);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const jsonData = await response.json();
          // Ensure jsonData is an array
          if (Array.isArray(jsonData.products)) {
            setProducts(jsonData.products);
          } else {
            console.error("Invalid data format: products is not an array");
          }
        } catch (error) {
          console.error("Error fetching data:", error, base_url);
        }
      }
    };
    fetchData();
  }, [base_url, admin]);
  useEffect(() => {
    AOS.init({
      // Global settings here
    });
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="subtitle mb20">Most complete</div>
          <h2 className="wow fadeInUp">Products Collection</h2>
          <div className="spacer-20"></div>
        </div>
        {/* <div className="col-lg-6 text-lg-end">
                    <Link className="btn-main mb-sm-30" to="/subscription">View all Products</Link>
                </div> */}
      </div>
      <div className="row g-4 sequence">
        {products.map((product) => (
          <div
            key={product.id}
            data-aos="fade-up"
            className="col-lg-3 col-md-6 gallery-item {styles['fade-up-mobile']}"
          >
            <div className="de-item wow">
              <div className="d-overlay">
                <div className="d-label">{product.discount}% OFF</div>
                <div className="d-text">
                  <h4>{product.name}</h4>
                  <p className="d-price">
                    Starting at{" "}
                    <span className="price">
                      ${product.showdetails[0].price}
                    </span>
                  </p>
                  <Link
                    className="btn-main btn-fullwidth"
                    to={{
                      pathname: `/product/${product.id}`,
                    }}
                  >
                    Order Now
                  </Link>
                </div>
              </div>
              <img
                src={base_url + `/product_upload/` + product.images}
                className="img-fluid "
                alt="turnitin"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Collection;
