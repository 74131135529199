import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from '../src/pages/home';
import Home1 from '../src/pages/home1';
import Home2 from '../src/pages/home2';
import Home3 from '../src/pages/home3';
import Home4 from '../src/pages/home4';
import Games from '../src/pages/games';
import Subscription from '../src/pages/subscription';
import Bundle from '../src/pages/bundle';
import Pricing2 from '../src/pages/pricing2';
import Pricing3 from '../src/pages/pricing3';
import Location from '../src/pages/location';
import Knowledgebase from '../src/pages/knowledgebase';
import Faq from '../src/pages/faq';
import Contact from '../src/pages/contact';
import Product from '../src/pages/product';
import Checkout from '../src/pages/checkout';
import News from '../src/pages/news';
import About from '../src/pages/about';
import Affliate from '../src/pages/affliate';
import Login from '../src/pages/login';
import Register from '../src/pages/register';
import Profile from '../src/pages/profile';
import History from '../src/pages/history';
import TermsConditions from '../src/pages/terms-and-conditions';
import PrivacyPolicy from '../src/pages/privacy-policy';
import ReturnPolicy from '../src/pages/return-policy';
import NotFound from '../src/pages/404';
import { DomainContext } from './index'; 

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const routes = [
  { path: '/', element: <Home /> },
  { path: '/product-detail', element: <Home1 /> },
  { path: '/home2', element: <Home2 /> },
  { path: '/home3', element: <Home3 /> },
  { path: '/home4', element: <Home4 /> },
  { path: '/games', element: <Games /> },
  { path: '/bundle', element: <Bundle /> },
  { path: '/subscription', element: <Subscription /> },
  { path: '/pricing2', element: <Pricing2 /> },
  { path: '/pricing3', element: <Pricing3 /> },
  { path: '/location', element: <Location /> },
  { path: '/knowledgebase', element: <Knowledgebase /> },
  { path: '/faq', element: <Faq /> },
  { path: '/contact', element: <Contact /> },
  { path: '/product/:id', element: <Product /> },
  { path: '/checkout', element: <Checkout /> },
  { path: '/news', element: <News /> },
  { path: '/about', element: <About /> },
  { path: '/affliate', element: <Affliate /> },
  { path: '/login', element: <Login /> },
  { path: '/register', element: <Register /> },
  { path: '/profile', element: <Profile /> },
  { path: '/history', element: <History /> },
  { path: '/terms-and-conditions', element: <TermsConditions /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  { path: '/return-policy', element: <ReturnPolicy /> },
  { path: '/404', element: <NotFound /> },
];

const Navigation = () => (
  <Routes>
    {routes.map(({ path, element }) => (
      <Route key={path} path={path} element={element} />
    ))}
  </Routes>
);

function App() {


  return (
    <HelmetProvider>
      <div>
        <BrowserRouter>
          <ScrollToTop />
          <Navigation />
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
