import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";    
import Navbar from "../layout/Navbar";
import Preloader from "../layout/preloader";
import Footer from "../section-pages/footer";
import ScrollToTopBtn from "../layout/ScrollToTop";
import { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";
const image1 = "../../img/background/subheader-contact.webp";

const GlobalStyles = createGlobalStyle`
  .react-parallax-bgimage {
    transform: translate3d(-50%, -12%, 0px) !important;
  }
`;

export default function Home() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);
  return (
    <>
      {/* HEAD */}
      <Helmet>
        <link rel="icon" href="./img/favicon.png" />
        <title>Premiums Tech Zone - Product</title>
      </Helmet>

      <GlobalStyles />

      {/* LOADER */}
      <div id="mainpreloader">
        <Preloader />
      </div>

      {/* MENU */}
      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* section */}
        <section>
        <div class="container pt-lg-5">
  <h1 class="font-display text-jacarta-700 text-center text-4xl font-medium dark:text-white">
    Terms Of Service
  </h1>
  <div class="article-content mx-auto max-w-[48.125rem] mt-6 dark:text-white">
    <h2>Introduction</h2>
    <p>
      These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Premiums Tech Zone, accessible at 
      <Link to="/" class="text-secondary"> https://premiumstechzone.com</Link>.
    </p>
    <p>
      These Terms will be applied fully and affect your use of this Website. By using this Website, you agree to accept all terms and conditions written here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.
    </p>
    
    <h2>Intellectual Property Rights</h2>
    <p>
      Other than the content you own, under these Terms, Premiums Tech Zone and/or its licensors own all the intellectual property rights and materials contained in this Website.
    </p>
    <p>
      You are granted a limited license only for purposes of viewing the material contained on this Website.
    </p>
    
    <h2>Restrictions</h2>
    <p>
      You are specifically restricted from all of the following:
    </p>
    <ol>
      <li>publishing any Website material in any other media;</li>
      <li>selling, sublicensing, and/or otherwise commercializing any Website material unless otherwise authorized;</li>
      <li>publicly performing and/or showing any Website material;</li>
      <li>using this Website in any way that is or may be damaging to this Website;</li>
      <li>using this Website in any way that impacts user access to this Website;</li>
      <li>using this Website contrary to applicable laws and regulations, or in any way that may cause harm to the Website, or to any person or business entity;</li>
      <li>engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this Website;</li>
      <li>using this Website to engage in any advertising or marketing.</li>
    </ol>
    
    <p>
      Certain areas of this Website are restricted from being accessed by you and Premiums Tech Zone may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.
    </p>
    
    <h2>Your Content</h2>
    <p>
      In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video text, images, or other material you choose to display on this Website. By displaying Your Content, you grant Premiums Tech Zone a non-exclusive, worldwide irrevocable, sublicensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.
    </p>
    <p>
      Your Content must be your own and must not invade any third-party’s rights. Premiums Tech Zone reserves the right to remove any of Your Content from this Website at any time without notice.
    </p>
    
    <h2>Your Privacy</h2>
    <p>
      This website safeguards your privacy in accordance with its own Privacy Policy, accessible at:
      <Link to="/privacy-policy" class="text-secondary"> https://premiumstechzone.com/privacy-policy</Link>
    </p>
    
    <h2>Warranties</h2>
    <p>
      This Website is provided "as is," with all faults, and Premiums Tech Zone makes no representations of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you. Any warranty will be covered under the Refunds, Returns, Exchanges & Shipping Policy located at:
      <Link to="/return-policy" class="text-secondary"> https://premiumstechzone.com/return-policy</Link>
    </p>
    
    <h2>Limitation of Liability</h2>
    <p>
      In no event shall Premiums Tech Zone, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract. Premiums Tech Zone, including its officers, directors, and employees, shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website.
    </p>
    
    <h2>Indemnification</h2>
    <p>
      You hereby indemnify to the fullest extent Premiums Tech Zone from and against any and all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.
    </p>
    
    <h2>Severability</h2>
    <p>
      If any provision of these Terms is found to be invalid under any applicable law, such provision shall be deleted without affecting the remaining provisions herein.
    </p>
    
    <h2>Variation of Terms</h2>
    <p>
      Premiums Tech Zone is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.
    </p>
    
    <h2>Assignment</h2>
    <p>
      Premiums Tech Zone is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
    </p>
    
    <h2>Entire Agreement</h2>
    <p>
      These Terms constitute the entire agreement between Premiums Tech Zone and you in relation to your use of this Website and supersede all prior agreements and understandings.
    </p>
    
    <h2>Governing Law & Jurisdiction</h2>
    <p>
      These Terms will be governed by and interpreted in accordance with the laws of the State of Us, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Us for the resolution of any disputes.
    </p>
  </div>
</div>

        </section>

        {/* footer */}
        <Footer />
      </div>
      <ScrollToTopBtn />
    </>
  );
}
