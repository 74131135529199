import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { DomainContext } from "..";

const Slider = () => {
  const { base_url, admin } = useContext(DomainContext);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      if (admin) {
        try {
          const api_url = base_url + "/api/" + "products-list/" + admin.id;
          const response = await fetch(api_url);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const jsonData = await response.json();
          // Ensure jsonData is an array
          if (Array.isArray(jsonData.products)) {
            setProducts(jsonData.products);
          } else {
            console.error("Invalid data format: products is not an array");
          }
        } catch (error) {
          console.error("Error fetching data:", error, base_url);
        }
      }
    };
    fetchData();
  }, [base_url, admin]);

  // useEffect(() => {
  //   console.log(products); // Log products whenever it changes
  // }, [products]);
  return (
    <Swiper
      className="mainslider"
      // install Swiper modules
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      navigation
      autoplay={{ delay: 4000 }}
      pagination={{
        clickable: false,
        renderBullet: function (index, className) {
          return `<span class="${className}">${
            index + 1
          } <span className="swiper-pagination-current">/ 4</span></span>`;
        },
      }}
    >
      {products.map((product) => (
        <SwiperSlide key={product.id}>
          <div
            className="swiper-inner banner-style"
            style={{
              backgroundImage: `url("./img/slider/gradient_dark.jpg")`,
              width: "100%",
              height: "100%",
            }}
          >
            <div className="sw-caption">
              <div className="container">
                <div className="row gx-5 align-items-center">
                  <div className="col-lg-8 mb-sm-30"></div>
                  <div className="col-lg-6">
                    <div className="subtitle blink mb-4">
                      Products Are Available
                    </div>
                    <h1 className="slider-title text-uppercase mb-1">
                      {product.name}
                    </h1>
                    <p className="slider-text">{product.description}</p>
                    <div className="sw-price wp">
                      <div className="d-starting">Starting at</div>
                      <div className="d-price">
                        <span className="d-cur">$</span>
                        <span className="d-val">
                          {product.showdetails[0].price}
                        </span>
                        <span className="d-period">
                          /{product.showdetails[0].duration}
                        </span>
                      </div>
                    </div>
                    <div className="spacer-10"></div>
                    <Link
                      className="btn-main mb10"
                      to={{
                        pathname: `/product/${product.id}`,
                      }}
                    >
                      Order Your Product Now
                    </Link>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-center">
                    <img
                      // src={`src="http://127.0.0.1:8001/product_upload/663e30a4ae716_Screenshot from 2024-05-07 18-42-26.png"`}
                      src={base_url + `/product_upload/` + product.images}
                      className="img-fluid slider-product"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="sw-overlay"></div>
          </div>
        </SwiperSlide>
      ))}

      {/* <SwiperSlide>
        <div
          className="swiper-inner banner-style"
          style={{
            backgroundImage: `url("./img/slider/gradient_dark.jpg")`,
            width: "100%",
            height: "100%",
          }}
        >
          <div className="sw-caption">
            <div className="container">
              <div className="row gx-5 align-items-center">
                <div className="col-lg-8 mb-sm-30"></div>
                <div className="col-lg-6">
                  <div className="subtitle blink mb-4">
                    Products Are Available
                  </div>
                  <h1 className="slider-title text-uppercase mb-1">
                    Course Hero
                  </h1>
                  <p className="slider-text">
                    Course Hero is a leading online learning platform that
                    offers a vast library of educational resources, including
                    study guides, course materials, and expert tutors,
                    empowering students to excel academically and reach their
                    full potential.
                  </p>
                  <div className="sw-price ">
                    <div className="d-starting">Starting at</div>
                    <div className="d-price">
                      <span className="d-cur">$</span>
                      <span className="d-val">12.99</span>
                      <span className="d-period">/monthly</span>
                    </div>
                  </div>
                  <div className="spacer-10"></div>
                  <Link className="btn-main mb10" to="/">
                    Order Your Product Now
                  </Link>
                </div>
                <div className="col-lg-6 d-flex justify-content-center">
                  <img
                    src="./img/covers/book2.png"
                    className="img-fluid slider-product"
                    alt="book2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sw-overlay"></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div
          className="swiper-inner banner-style"
          style={{
            backgroundImage: `url("./img/slider/gradient_dark.jpg")`,
            width: "100%",
            height: "100%",
          }}
        >
          <div className="sw-caption">
            <div className="container">
              <div className="row gx-5 align-items-center">
                <div className="col-lg-8 mb-sm-30"></div>
                <div className="col-lg-6">
                  <div className="subtitle blink mb-4">
                    Products Are Available
                  </div>
                  <h1 className="slider-title text-uppercase mb-1">
                    Office 365
                  </h1>
                  <p className="slider-text">
                    Office 365 is a comprehensive suite of productivity tools
                    and cloud services offered by Microsoft, including popular
                    applications like Word, Excel, PowerPoint, Outlook, and
                    OneDrive.{" "}
                  </p>
                  <div className="sw-price ">
                    <div className="d-starting">Starting at</div>
                    <div className="d-price">
                      <span className="d-cur">$</span>
                      <span className="d-val">14.99</span>
                      <span className="d-period">/monthly</span>
                    </div>
                  </div>
                  <div className="spacer-10"></div>
                  <Link className="btn-main mb10" to="/">
                    Order Your Product Now
                  </Link>
                </div>
                <div className="col-lg-6 d-flex justify-content-center">
                  <img
                    src="./img/covers/office.png"
                    className="img-fluid slider-product"
                    alt="office"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sw-overlay"></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div
          className="swiper-inner banner-style"
          style={{
            backgroundImage: `url("./img/slider/gradient_dark.jpg")`,
            width: "100%",
            height: "100%",
          }}
        >
          <div className="sw-caption">
            <div className="container">
              <div className="row gx-5 align-items-center">
                <div className="col-lg-8 mb-sm-30"></div>
                <div className="col-lg-6">
                  <div className="subtitle blink mb-4">
                    Products Are Available
                  </div>
                  <h1 className="slider-title text-uppercase mb-1">
                    Adobe Acrobat Pro
                  </h1>
                  <p className="slider-text">
                    Adobe Acrobat Pro 2023 is the latest version of Adobe's
                    industry-leading PDF editing and document management
                    software.{" "}
                  </p>
                  <div className="sw-price ">
                    <div className="d-starting">Starting at</div>
                    <div className="d-price">
                      <span className="d-cur">$</span>
                      <span className="d-val">15.99</span>
                      <span className="d-period">/monthly</span>
                    </div>
                  </div>
                  <div className="spacer-10"></div>
                  <Link className="btn-main mb10" to="/">
                    Order Your Product Now
                  </Link>
                </div>
                <div className="col-lg-6 d-flex justify-content-center">
                  <img
                    src="./img/covers/adobe.png"
                    className="img-fluid slider-product"
                    alt="turnitine"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sw-overlay"></div>
        </div>
      </SwiperSlide> */}
    </Swiper>
  );
};
export default Slider;
